import { ugmLogin } from "../user-group-management";
import {showModal} from "../../modal/modal";
import { forceHideOverlay } from "../../overlay/overlay";
import configuration from "../../../configuration";
import { getParam, onEnterDo } from "../../_helper";
import t, { getLang } from "../../i18n";

import "./login.css";
import { isLoggedIn } from "../user-data";

export const showRegisterModal = (userCantClose = false, institutionCode = "") => {
	const registerHtml = `
		<register-modal institutionCode="${institutionCode}"></register-modal>
	`;
	showModal(registerHtml, "centered-box", userCantClose);
};

export const showLoginModal = (userCantClose = false) => {
	const pwResetLink = `<a href="${configuration.ugmEndpoint}/reset-password" target="_blank">${t().loginInfoPasswordLink}</a>`;

	const registerText = configuration.target === "geografie"
		? t().registerTextGeo
		: configuration.enforceUGMLogin
			? t().registerTextEnforced
			: t().registerText;

	const resetLink = (configuration.languages.length > 1) && (getLang().toLowerCase() !== 'de')
		? configuration.ugmEndpoint + getLang().toLowerCase() + '/reset-password'
		: configuration.ugmEndpoint + 'reset-password';

	const loginInfoUsername = configuration.target === "geografie"
		? t().loginInfoUsername365
		: t().loginInfoUsername;

	const loginInfoPassword = configuration.target === "geografie"
		? t().loginInfoPassword365(pwResetLink)
		: t().loginInfoPassword;

	const login365Hint = configuration.enableMS365Login
		? `<span class="login-modal-hint">${t().login365Hint}</span>`
		: '';

	const showVidis = configuration.enableVidisLogin; //&& getParam('show-vidis');

	const loginVidisHint = showVidis
		? `<span class="login-modal-hint">${t().loginVidisHint}</span>`
		: '';

	const openRegisterModal = () => showRegisterModal(userCantClose);

	const html = `<div class="login-modal">
		<form>
			<span class="errors"></span>
			<label>
				<span>${t().userName}
					<details class="hint">
						<summary title="Info">i</summary>
						<div style="text-align:left;">${loginInfoUsername}</div>
					</details>
				</span>
				<input tabindex="1" type="text" name="username" value="" placeholder="${t().userName}"/>
			</label>
			<label>
				<span>${t().password}
					<details class="hint">
						<summary title="Info">i</summary>
						<div style="text-align:left;">${loginInfoPassword}</div>
					</details>
				</span>
				<input tabindex="2" type="password" name="password" value="" placeholder="${t().password}"/>
			</label>
			<input type="submit" value="${t().login}"/>
			${ showVidis ? `<a class="button vidis-button" href="/api/auth/login-ms365" type="submit">${t().loginVidisLabel}</a>` : "" }
			${loginVidisHint}
			${ configuration.enableMS365Login ? `<a class="button" href="/api/auth/login-ms365" type="submit">${t().login365Label}</a>` : "" }
			${login365Hint}
			<br/>
			<a id="register-link" class="ugm-register-link">${registerText}</a><br />
			<a class="ugm-register-link" href="${resetLink}">${t().loginInfoPasswordLink}</a>
		</form>
	</div>`;
	const modal = showModal(html, "centered-box", userCantClose);
	const errorNode = modal.querySelector(".errors");
	const form = <HTMLFormElement | null>modal.querySelector("form");
	if(!form || !errorNode){return;}
	const usernameElement = <HTMLInputElement | null>form.querySelector('input[name="username"]');
	const passwordElement = <HTMLInputElement | null>form.querySelector('input[name="password"]');
	if(!usernameElement || !passwordElement){return;}
	usernameElement.focus();
	const onSubmit = async (e:Event) => {
		e.preventDefault();
		const username = usernameElement.value;
		const password = passwordElement.value;
		try {
			const loginStatus = await ugmLogin(username, password);
			if(loginStatus){
				errorNode.innerHTML = '';
				forceHideOverlay();
			}else{
				errorNode.innerHTML = `${t().loginError}<br/><br/>`;
			}
		}catch(e) {
			errorNode.innerHTML = `${t().loginError}<br/><br/>`;
		}
	};
	usernameElement.addEventListener("keypress", onEnterDo((e:Event) => {
		e.preventDefault();
		passwordElement.focus();
	}));
	passwordElement.addEventListener("keypress", onEnterDo(onSubmit));

	form.addEventListener("submit", onSubmit);

	const registerButton = modal.querySelector<HTMLAnchorElement>('#register-link');

	registerButton?.addEventListener("click", openRegisterModal);
};

export const initUGMLoginButton = () => {
	const loginButton = document.querySelectorAll<HTMLElement>("login-button");
	if(!loginButton){return;}
	loginButton.forEach(btn =>{
		btn.addEventListener("click", e => {
			e.stopPropagation();
			showLoginModal();
		});
	});
};
setTimeout(initUGMLoginButton,0);

if(configuration.ugmEndpoint){
	setTimeout(async () => {
		if(isLoggedIn()){
			return;
		}
		const urlParams = new URLSearchParams(window.location.search);
		const loginModal = urlParams.get("login-modal");
		if (loginModal){
			showLoginModal();
		}
	},100);
}
